/* You can add global styles to this file, and also import other style files */

@import '~antd/dist/antd.css';
@import '~@repay/react-credit-card/dist/react-credit-card.css';

html,
body,
#root {
  height: 100%;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  background: #ededed;
  color: #787878;
}

#root {
  // width: 113.6rem;
  // max-width: 113.6rem;
  // margin: 3rem auto 0;
}

.pagination {
  display: inline-block;
  padding: 0;
  font-weight: bold;

  li {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    text-align: center;

    &.disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &.active {
      background-color: #1890ff;
      border-radius: 100%;
      a {
        color: #fff;
      }
    }
  }
}
